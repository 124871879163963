import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"
import "../mystyles.scss"

import b1 from "../../img/maximal_race/b1.png"
import b2 from "../../img/maximal_race/b2.png"
import p1 from "../../img/maximal_race/p1.png"
import p2 from "../../img/maximal_race/p2.png"
import { Helmet } from "react-helmet"

export default function MaximalRace() {
  const rowArr = []

  for (let index = 1; index <= 30; index++) {
    rowArr.push(index)
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Maximal Race 2021</title>
      </Helmet>
      <SmallHero title="Kangourou Maximal Race 2021" color="is-success" />
      <article className="section">
        <div className="container">
          <h1 className="title">À la fin, il n’en restera qu’un !</h1>
          <p>
            Le concept est simple : Prendre le départ, courir une première fois
            le parcours, puis récupérer tranquillement, et tout cela en 30
            minutes et pas une seconde de plus ! Tu repars ensuite pour une
            seconde boucle, puis une troisième, et une quatrième, etc… enfin tu
            as compris le truc ?!
          </p>
          <br />
          <h1 className="title">Information générale</h1>
          <div className="has-text-centered has-text-weight-semibold">
            Date : Samedi 13 novembre
            <br />
            Lieu de rendez-vous : Rue du Nord 5 ; 1700 Fribourg ; Suisse
            <br />
            Départ groupe 1 (boucle 2,8km) : 18h00
            <br />
            Départ groupe 2 (boucle 2,5km) : 18h00
          </div>
          <br />
          <p className="has-text-weight-semibold">
            Attention : Vous devez vous rendre sur place minimum 30 minutes
            avant le départ !
          </p>
          <p>
            <div className="content">
              Un ravitaillement constant est disponible pour tous les coureurs
              entre chaque boucle. Ce ravitaillement est composé de :
              <ul>
                <li>Boissons froides sucrées (thé-froid, coca, etc…)</li>
                <li>Boissons chaudes (Thé chaud, Café)</li>
                <li>Biscuits</li>
                <li>Fruits (bananes, pommes, mandarines, raisins)</li>
                <li>Bonbons </li>
                <li>Pain </li>
                <li>
                  Entre 21h00-22h30 et 02h00-03h00 : Pâtes sauce bolognese et
                  pesto Rosso
                </li>
              </ul>
            </div>
            <br />
            Chaque participante et participant doit venir en habits de sport !
            Néanmoins, à la fin de la course, ils auront la possiblité de se
            doucher et se changer, donc ils peuvent prendre des affaires propres
            pour l’after-course.
          </p>
          <br />
          <h1 className="title">Inscription</h1>
          Les inscriptions se font via le formulaire suivant :{" "}
          <a href="https://forms.gle/fGZ7fvRaTJjKKcLXA">
            Lien vers le formulaire
          </a>
          <br />
          <br />
          <h1 className="title">Parcours et profils</h1>
          <br />
          <h2 className="subtitle">Boucle 2.5km</h2>
          <div className="columns">
            <div className="column is-half">
              <h2 className="subtitle has-text-centered">Le parcours</h2>
              <figure className="image">
                <img src={b1} alt="parcours 5km" />
              </figure>
            </div>
            <div className="column is-half has-text-centered">
              <h2 className="subtitle">Le dénivelé</h2>
              <figure className="image">
                <img
                  src={p1}
                  alt="profil du parcours"
                  style={{
                    maxWidth: "75%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </figure>
            </div>
          </div>
          <br />
          <h2 className="subtitle">Boucle 2.8km</h2>
          <div className="columns">
            <div className="column is-half">
              <h2 className="subtitle has-text-centered">Le parcours</h2>
              <figure className="image">
                <img src={b2} alt="parcours 5km" />
              </figure>
            </div>
            <div className="column is-half has-text-centered">
              <h2 className="subtitle">Le dénivelé</h2>
              <figure className="image">
                <img
                  src={p2}
                  alt="profil du parcours"
                  style={{
                    maxWidth: "75%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </figure>
            </div>
          </div>
          <h1 className="title">Tableau récapitulatif </h1>
          <div class="columns is-mobile is-centered">
            <div class="column">
              <table class="table is-bordered">
                <thead>
                  <tr>
                    <th>
                      <abbr>Boucle</abbr>
                    </th>
                    <th>
                      <abbr>Heure</abbr>
                    </th>
                    <th>
                      <abbr>Parcours 2.8km</abbr>
                    </th>
                    <th>
                      <abbr>Parcours 2.5km</abbr>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rowArr.map((item, index) => {
                    let time = new Date(2020, 11, 28, 18, 30 * index, 0, 0)
                    return (
                      <tr key={index}>
                        <td>{item - 1}</td>
                        <td>{`${time.getHours()}h${
                          time.getMinutes() === 30 ? 30 : "00"
                        }`}</td>
                        <td>{(2.8 * (item - 1)).toFixed(1)} km</td>
                        <td>{`${(2.5 * (item - 1)).toFixed(1)} km`}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}
